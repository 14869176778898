<template>
  <div class="page">
    <!-- <top-bar :title="'社会救助站'" :left="true"></top-bar> -->
    <div class="header">
      <van-dropdown-menu active-color="#0079fe">
        <van-dropdown-item v-model="menuValue1" :options="menuOption1" style="width:100px" @change="menuChange('type')" />
        <van-dropdown-item v-model="menuValue2" :options="menuOption2" style="width:150px" @change="menuChange('status')" />
      </van-dropdown-menu>
    </div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="content" v-if="listData.length > 0">
        <div class="list">
          <div class="item" v-for="(item, index) in listData" @click="item.isNav?navToDetail(item):''" :key="index">
            <div class="top">
              <div class="text">
                {{ item.userName }} | {{ item.createTime || "求助时间未知" }}
              </div>
              <div class="label" :class="item.salvationStatus == 4? 'text-blue': 'text-red'">{{ item.salvationStatusName }}</div>
            </div>
            <div class="detail">
              <div class="img">
                <img :src="item.headImg?item.headImg:require('@/assets/img/header.jpg')" />
              </div>
              <div class="info">
                <div class="address">
                  <span>{{ item.specificPlace }}</span>
                  <span class="t">（{{ item.helpTypeName.join('') }}）</span>
                </div>
                <div class="text">{{ item.salvationDesc || "暂无描述" }}</div>
              </div>
              <div class="icon" v-if="item.isNav">
                <span class="iconfont icon-youbian" style="color: #999;"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-empty description="暂无数据" v-else />
    </van-pull-refresh>
    <div class="addBtn" @click="addFun">
      <img src="../../assets/img/add.png" class="addImg" />
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import { Toast } from "vant";
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: 0,
      limit: 100,
      listData: [],
      menuValue1: "",
      menuValue2: "",
      menuOption1: [{ text: "求助类型", value: "" }],
      menuOption2: [
        { text: "求助状态", value: "" },
        { text: "待审核", value: 0 },
        { text: "审核通过", value: 1 },
        { text: "救助中", value: 2 },
        { text: "救助完成,待评价", value: 3 },
        { text: "已评价", value: 4 },
        { text: "审核不通过", value: 6 },
      ],
      isLoading: false
    };
  },
  created() {
    this.getListDictByCode("salvationType");
  },
  methods: {
    getDataList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appjqsalvation/list"),
        method: "get",
        params: this.$httpApp.adornParams({
          orgId: this.$orgId,
          page: this.page,
          limit: this.limit,
          helpType: this.menuValue1,
          salvationStatus: this.menuValue2,
        }),
      }).then(({ data }) => {
        this.$toast.clear();
        if (data && data.code === 0) {
          this.isLoading = false
          // console.log(data.page.list)
          this.listData = data.page.list;
          if (data.page.list) {
            data.page.list.map(item => {
              if (item.salvationStatus == 0 || item.salvationStatus == 1 || item.salvationStatus == 2 || item.salvationStatus == 3 || item.salvationStatus == 4 || (item.salvationStatus == 6 && item.isAgain == 0)) {
                item.isNav = true
              } else {
                item.isNav = false
              }
            })
          }
        } else {
          this.isLoading = false
          Toast.fail(data.msg);
        }
      });
    },
    getListDictByCode(code) {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/listDictByCode`),
        method: "get",
        params: this.$http.adornParams({ code }),
      }).then(({ data }) => {
        if (data.code == 0) {
          data.dicts.map((item) => {
            this.menuOption1.push({ text: item.label, value: item.value });
          });
          this.getDataList();
        } else {
          Toast.fail(data.msg);
        }
      });
    },
    onRefresh() {
      this.getDataList()
    },
    menuChange() {
      this.getDataList();
    },
    navToDetail(item) {
      console.log(item);
      this.$router.push({ name: "rescue-detail", query: { id: item.id } });
    },
    addFun() {
      this.$router.push({ name: "rescue-add" });
    },
  },
  components: {
    topBar,
  },
};
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #f5f5f5;
  box-sizing: border-box;
  .header {
    border-top: 1px solid #eee;
  }
  .content {
    padding: 20px 30px;
    background: #f5f5f5;
    .list {
      .item {
        width: 100%;
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        margin: 0 auto 20px;
        padding: 20px;
        font-size: 26px;
        .top {
          border-bottom: 1px solid #f1f1f1;
          padding-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .text {
            font-size: 26px;
            color: #656565;
          }
        }
        .detail {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 20px 10px 0 10px;
          .img {
            width: 100px;
            height: 100px;
            overflow: hidden;
            margin-right: 30px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .info {
            width: 420px;
            .address {
              color: #000;
              font-weight: bold;
              .t {
                font-weight: normal;
                color: #0079fe;
              }
              margin-bottom: 20px;
            }
            .text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #999;
            }
          }
          .icon {
            flex: 1;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
